import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-top: 2%;
  justify-content: center;
  height: 87vh;
  animation-name: up;
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;

  @keyframes up {
    from {
        opacity: 0;
        transform: translateY(25px)
    }
    
    to {
        opacity: 1;
    }
}

@keyframes opacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

`;

export const Form = styled.form`
  width: 450px;
  background-color: #ebebf2;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 350px;
    margin: 10px 0 40px;
  }

  p {
    color: #ff3333;
    margin-bottom: 15px;
    border: 1px solid #ff3333;
    padding: 10px;
    width: 100%;
    text-align: center;
    animation-name: up;
    animation-duration: 300ms;
    animation-timing-function: ease-in-out;

    @keyframes up {
      from {
          opacity: 0;
          transform: translateY(25px)
      }
      
      to {
          opacity: 1;
      }
    }
  }
  
  h3 {
    margin-bottom: 20px;
    color: #1486ff;
  }

  input {
    height: 46px;
    margin-bottom: 15px;
    padding: 15px 20px;
    color: #777;
    font-size: 15px;
    width: 100%;
    border: 1px solid #ddd;
    &::placeholder {
      color: var(--text-fade);
    }
  }

  label {
    margin-bottom: 0.5em;
    display: block;
    margin-right: auto;
    margin-left: 5%;
    color: #777;
  }
  
  .link {
    width: 100%;
  }

  button {
    color: #fff;
    font-size: 15px;
    background: #1486ff;
    height: 56px;
    border: 0;
    border-radius: 5px;
    width: 100%;
    transition: 200ms;
    margin-bottom: 10px;
  }

  button:hover {
    background: #03368a;
  }

  hr {
    margin: 20px 0;
    border: none;
    border-bottom: 1px solid #cdcdcd;
    width: 100%;
  }

  a {
    font-size: 16;
    font-weight: bold;
    color: #999;
    text-decoration: none;
  }
`;
