import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-top: 60px;
  justify-content: center;
  height: 50vh;
  animation-name: up;
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;

  @keyframes up {
    from {
        opacity: 0;
        transform: translateY(25px)
    }
    
    to {
        opacity: 1;
    }
}

@keyframes opacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

`;

export const Box = styled.div`
  width: 400px;
  background: #ebebf2;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .link {
    width: 100%;
  }
  button {
    color: #fff;
    font-size: 16px;
    background: #1486ff;
    height: 56px;
    border: 0;
    border-radius: 5px;
    width: 100%;
    transition: 200ms;
    margin: 20px 0px 8px 0px;
  }

  button:hover {
    background: #03368a;
  }

  a {
    font-size: 16;
    font-weight: bold;
    color: #999;
    text-decoration: none;
  }
`;
