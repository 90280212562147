import React from 'react';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';

export const SidebarData = [
  {
    title: 'Página Inicial',
    path: '/',
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: 'Projetos',
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Listar Projetos',
        path: '/projetos',
        icon: <IoIcons.IoIosArchive />,
        cName: 'sub-nav',
      },
      {
        title: 'Criar Projeto',
        path: '/projetos/create',
        icon: <IoIcons.IoIosAddCircleOutline />,
        cName: 'sub-nav',
      },
    ],
  },
  {
    title: 'Usuários',
    icon: <IoIcons.IoMdPeople />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Listar Usuários',
        path: '/users/',
        icon: <IoIcons.IoIosPeople />,
        cName: 'sub-nav',
      },
      {
        title: 'Criar Usuário',
        path: '/users/create',
        icon: <IoIcons.IoIosPersonAdd />,
        cName: 'sub-nav',
      },
    ],
  },
  {
    title: 'Ajuda',
    path: '/support',
    icon: <IoIcons.IoMdHelpCircle />,
  },
];
