import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Container, Box } from './styles';
import Sidebar from '../../../components/Sidebar/Sidebar';
import api from '../../../services/api';

class Remove extends Component {
    state = {
      title: '',
      error: '',
    };

    componentDidMount() {
      this.getProject();
    }

    async handleDelete() {
      try {
        const { id } = this.props.match.params;
        await api.delete(`/projects/${id}`);
        this.props.history.push('/projetos');
      } catch (error) {
        this.setState({ error: 'Houve um erro enquanto tentavamos deletar este projeto!' });
      }
    }

    async getProject() {
      try {
        const { id } = this.props.match.params;
        const response = await api.get(`/projects/${id}`);
        this.setState({
          title: response.data.project.title,
        });
      } catch (error) {
        this.setState({ error: 'Houve um problema com a busca do projeto!' });
      }
    }

    render() {
      const { title } = this.state;
      return (
        <>
          <Sidebar />
          <Container>
            <Box>
              <h2>
                Você deseja mesmo remover o projeto
                {' '}
                {title}
              </h2>
              <button type="button" onClick={() => { this.handleDelete(); }}>Sim</button>
              <Link to="/projetos" className="link"><button type="button">Cancelar</button></Link>
            </Box>
          </Container>
        </>
      );
    }
}

export default withRouter(Remove);
