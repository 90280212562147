import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import FormComponentProject from '../../../components/FormComponentProject';
import Sidebar from '../../../components/Sidebar/Sidebar';
import api from '../../../services/api';

class EditProjects extends Component {
  constructor(props) {
    super(props);
    this.refresh = this.refresh.bind(this);
    this.props = props;
  }

    state = {
      title: '',
      description: '',
      equipments: '',
      error: '',
    };

    componentDidMount() {
      this.refresh();
    }

    handleSubmit = async (e) => {
      e.preventDefault();
      const { title, description, equipments } = this.state;
      if (!title || !description || title === '' || description === '') {
        this.setState({ error: 'Preencha todos os campos para continuar!' });
      } else {
        try {
          const { id } = this.props.match.params;
          await api.put(`/projects/${id}`, { title, description, equipments });
          this.props.history.push('/projetos');
        } catch (err) {
          this.setState({ error: 'Houve um problema para atualizar, verifique os dados preenchidos!' });
        }
      }
    };

    async getProjetos() {
      try {
        const { id } = this.props.match.params;
        const response = await api.get(`/projects/${id}`);
        this.setState({
          title: response.data.project.title,
          description: response.data.project.description,
          equipments: response.data.project.equipments,
        });
      } catch (err) {
        this.setState({ error: 'Houve um problema com a busca do projetos!' });
      }
    }

    refresh() {
      this.getProjetos();
    }

    render() {
      const { title, description, error } = this.state;
      return (
        <>
          <Sidebar />
          <FormComponentProject
            onSubmit={() => this.handleSubmit}
            onChangeTitle={(e) => { this.setState({ title: e.target.value }); }}
            onChangeDescription={(e) => { this.setState({ description: e.target.value }); }}
            to="/projetos"
            error={error}
            title={title}
            description={description}
            action="Atualizar projeto"
            titulo="Editar este projeto:"
          />
        </>
      );
    }
}

export default withRouter(EditProjects);
