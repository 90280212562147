/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { FiTrash } from 'react-icons/fi';
import { Container, Table } from './styles';
import Sidebar from '../../../components/Sidebar/Sidebar';
import api from '../../../services/api';

class ListUsers extends Component {
  state = {
    users: [],
    error: '',
  }

  componentDidMount() {
    this.getUsers();
  }

  async getUsers() {
    try {
      const usuarios = [];
      const response = await api.get('/auth/users');
      const { user } = response.data;
      user.map((users) => {
        if (users.project != null) {
          usuarios.push(users);
        }
        
      });
      this.setState({ users: usuarios });
    } catch (err) {
      this.setState({ error: 'Houve um problema ao carregar os usuários' });
    }
  }

  render() {
    const { users } = this.state;

    return (
      <>
        <Sidebar />
        <Container>
          <Table striped>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Sobrenome</th>
                <th>E-mail</th>
                <th>Função</th>
                <th>Projeto</th>
                <th>Remover</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user.nome}</td>
                  <td>{user.sobrenome}</td>
                  <td>{user.email}</td>
                  <td>{user.roleAssigned === 'adm' ? "Administrador" : user.roleAssigned === 'moderator' ? "Moderador" : user.roleAssigned === 'visualizer' ? "Visualizador" : "Não possui função definida" }</td>
                  <td>{user.project.title ? user.project.title : "Não possui projeto definido" }</td>
                  <td>
                    <Link to={`/user/remove/${user._id}`}>
                      <button type="button" className="remove">
                        <FiTrash />
                      </button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      </>
    );
  }
}

export default withRouter(ListUsers);
