import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import logo from '../../assets/logo/logo.png';

class Home extends Component {
  render() {
    return (
      <>
        <Sidebar />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={logo} alt="InvEng Logo" />
          <h1>Home Dashboard</h1>

        </div>

      </>
    );
  }
}

export default withRouter(Home);
