/* eslint-disable camelcase */
/* eslint-disable object-shorthand */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import FormComponentEquipment from '../../../components/FormComponentEquipment';
import Sidebar from '../../../components/Sidebar/Sidebar';
import api from '../../../services/api';

class Create extends Component {
    state = {
      id: '',
      name: '',
      status: '',
      tipo: '',
      last_inspection: '',
      next_inspection: '',
      relatorio: '',
      ccpsv: '',
      ccmanometro: '',
      ccpressostato: '',
      assignedTo: '',
      project: '',
      error: '',
    };

    componentDidMount() {
      this.getProject();
    }

    handleSubmit = async (e) => {
      e.preventDefault();
      const {
        id, name, status, tipo, last_inspection, next_inspection, relatorio, ccpsv, ccmanometro, ccpressostato, assignedTo, project,
      } = this.state;
      if (!id || !name || !status || !tipo || !last_inspection || !next_inspection || id === '' || name === '' || status === '' || tipo === '' || last_inspection === '' || next_inspection === '') {
        this.setState({ error: 'Preencha todos os campos para continuar!' });
      } else {
        try {
          await api.post('/equipments', {
            id, name, status, tipo, last_inspection, next_inspection, relatorio, ccpsv, ccmanometro, ccpressostato, assignedTo, project,
          });
          this.goToTheBack();
        } catch (err) {
          this.setState({ error: 'Houve um problema com o cadastro, verifique os dados preenchidos!' });
        }
      }
    };

    async getProject() {
      try {
        const { id } = this.props.match.params;
        const response = await api.get(`/projects/${id}`);
        this.setState({
          assignedTo: response.data.project.user._id,
          project: response.data.project._id,
        });
      } catch (err) {
        this.setState({ error: 'Houve um problema para coletar os dados do projeto atual' });
      }
    }

    goToTheBack() {
      try {
        this.props.history.goBack();
      } catch (err) {
        this.setState({ error: 'Houve um erro para retornar a página anterior' });
      }
    }

    render() {
      const { error } = this.state;

      return (
        <>
          <Sidebar />
          <FormComponentEquipment
            onSubmit={() => this.handleSubmit}
            onChangeId={(e) => { this.setState({ id: e.target.value }); }}
            onChangeName={(e) => { this.setState({ name: e.target.value }); }}
            onChangeTipo={(e) => { this.setState({ tipo: e.target.value }); }}
            onChangeStatus={(e) => { this.setState({ status: e.target.value }); }}
            onChangeLastInspection={(e) => { this.setState({ last_inspection: e.target.value }); }}
            onChangeNextInspection={(e) => { this.setState({ next_inspection: e.target.value }); }}
            onChangeRelatorio={(e) => { this.setState({ relatorio: e.target.value }); }}
            onChangeCCPSV={(e) => { this.setState({ ccpsv: e.target.value }); }}
            onChangeCCManometro={(e) => { this.setState({ ccmanometro: e.target.value }); }}
            onChangeCCPressostato={(e) => { this.setState({ ccpressostato: e.target.value }); }}
            onCancel={() => { this.goToTheBack(); }}
            error={error}
            action="Cadastrar equipamento"
            titulo="Cadastrar um novo equipamento:"
          />
        </>
      );
    }
}

export default withRouter(Create);
