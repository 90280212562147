import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 60%;
  margin: auto;
`;

export const Table = styled.table`
  background-color: #ebebf2;
  width: 100%;
  margin: 50px 0px;
  text-align: center;
  padding: 30px;
  span {
    display: flex;
    align-items: center;
  }
  th {
    padding: 25px;
  }
  td {
    align-items: center;
    justify-content: center;
    padding: 10px;
    }
  button {
    background-color: #1486ff ;
    color: #fff;
    display:flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    height: 40px;
    width: 74px;
    border: 0px;
    font-size: 20px;
    border-radius: 8px;
    transition: 200ms  ease-in-out;
    span {
      display: flex;
      align-items: center;
    }
  }

  button:hover {
    background-color: #03368a ;
    transform: translateY(-2px);
  }

  .remove {
    background-color: #ff3333;
    text-decoration: none;
    transition: 200ms;
  }

  .remove:hover {
    background-color: #a62121;
    transform: translateY(-2px);
  }

  .name {
    text-align: start;
  }
`;
