import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { login, userData, userRole, userProject} from '../../services/auth';
import { Form, Container } from './styles';
import api from '../../services/api';
import logo from '../../assets/logo/logo.png';
import Copyright from '../../components/Copyright';
import TextField from '../../components/TextField/TextField';

class SignIn extends Component {
  state = {
    email: '',
    password: '',
    error: '',
  };

  handleSignIn = async (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    if (!email || !password) {
      this.setState({ error: 'Preencha todos os campos para continuar!' });
    } else {
      try {
        const response = await api.post('/auth/authenticate', { email, password });
        login(response.data.token);
        userData(response.data.user);
        const { location } = this.props;
        if (userRole() === 'moderator'){
          if (location.state && location.state.from.pathname !== '/') {
            this.props.history.push(location.state.from.pathname);
          } else {
            this.props.history.push(`/projetos/${userProject()}/equipments`);
          }
        } else if (userRole() === 'visualizer'){
          if (location.state && location.state.from.pathname !== '/') {
            this.props.history.push(location.state.from.pathname);
          }
        }else {
          if (location.state && location.state.from.pathname !== '/') {
            this.props.history.push(location.state.from.pathname);
          } else {
            this.props.history.push('/');
          }          
        }

      } catch (err) {
        this.setState({
          error:
            'Houve um problema com o login, verifique seu usuário e senha.',
        });
      }
    }
  };

  render() {
    return (
      <Container>
        <Form onSubmit={this.handleSignIn}>
          <div className="container-logo">
            <img src={logo} alt="InvEng Logo" />
          </div>
          <div>
            {this.state.error && <p className="box-error">{this.state.error}</p>}
          </div>
          <div className="container-forms">
            <div className="container-name">
              <h2 className="login-name">Login</h2>
            </div>
            <div className="forms-input">
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => this.setState({ email: e.target.value })}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="password"
                label="Senha"
                type="password"
                name="password"
                autoComplete="current-password"
                onChange={(e) => this.setState({ password: e.target.value })}
              />
            </div>
            <button type="submit">Acessar</button>
            <hr />
          </div>
          <Box mt={8}>
            <Copyright className="box-copyright" />
          </Box>
        </Form>
      </Container>
    );
  }
}
export default withRouter(SignIn);
