import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  FiTrash, FiEdit3, FiEye, FiFilePlus,
} from 'react-icons/fi';
import { Container, Table, CreateEquipments } from './styles';
import Sidebar from '../../../components/Sidebar/Sidebar';
import api from '../../../services/api';

class ListEquipments extends Component {
  state = {
    equipments: [],
    error: '',
  }

  componentDidMount() {
    this.getProjects();
  }

  async getProjects() {
    try {
      const equipamentos = [];
      const response = await api.get('/equipments');

      const { equipments } = response.data;

      equipments.map((equipment) => {
        if (equipment.project != null) {
          if (this.props.match.params.id === equipment.project._id) {
            equipamentos.push(equipment);
          }
        }
      });

      this.setState({ equipments: equipamentos });
    } catch (err) {
      this.setState({ error: 'Houve um problema ao carregar os projetos' });
    }
  }

  render() {
    const { equipments } = this.state;

    return (
      <>
        <Sidebar />

        <Container>
          <Table>
            <thead>
              <tr>
                <th>
                  <CreateEquipments>
                    <Link to={`/projetos/${this.props.match.params.id}/equipments/create`}>
                      <button type="button">
                        <FiFilePlus />
                      </button>
                    </Link>
                  </CreateEquipments>
                </th>
                <th>TAG</th>
                <th>Nome</th>
                <th>Tipo</th>
                <th>Status</th>
                <th>Última Inspeção</th>
                <th>Proxima Inspeção</th>
                <th>Editar</th>
                <th>Remover</th>
              </tr>
            </thead>
            <tbody>
              {equipments.map((equipment) => (
                <tr key={equipment._id}>
                  <td>
                    <Link to={`/projetos/${this.props.match.params.id}/equipments/view/${equipment._id}`}>
                      <button type="button">
                        <FiEye />
                      </button>
                    </Link>
                  </td>
                  <td>{equipment.id}</td>
                  <td>{equipment.name}</td>
                  <td>{equipment.tipo}</td>
                  <td>{equipment.status}</td>
                  <td>{`${equipment.last_inspection.split('T')[0].split('-')[2]}/${equipment.last_inspection.split('T')[0].split('-')[1]}/${equipment.last_inspection.split('T')[0].split('-')[0]}`}</td>
                  <td>{`${equipment.next_inspection.split('T')[0].split('-')[2]}/${equipment.next_inspection.split('T')[0].split('-')[1]}/${equipment.next_inspection.split('T')[0].split('-')[0]}`}</td>
                  <td>
                    <Link to={`/projetos/${this.props.match.params.id}/equipments/edit/${equipment._id}`}>
                      <button type="button">
                        <FiEdit3 />
                      </button>
                    </Link>
                  </td>
                  <td>
                    <Link to={`/projetos/${this.props.match.params.id}/equipments/remove/${equipment._id}`}>
                      <button type="button" className="remove">
                        <FiTrash />
                      </button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      </>
    );
  }
}

export default withRouter(ListEquipments);
