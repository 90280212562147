export const isAuthenticated = () => localStorage.getItem('TOKEN') !== null;
export const getToken = () => localStorage.getItem('TOKEN');
export const login = (token) => {
  localStorage.setItem('TOKEN', token);
};
export const userData = (userPayload) => {
  localStorage.setItem('USER_FIRST_NAME', userPayload.nome)
  localStorage.setItem('USER_LAST_NAME', userPayload.sobrenome)
  localStorage.setItem('USER_EMAIL', userPayload.email)
  localStorage.setItem('USER_ROLE', userPayload.roleAssigned)
  localStorage.setItem('USER_PROJECT', userPayload.project)
}
export const userRole = () => localStorage.getItem('USER_ROLE');
export const userProject = () => localStorage.getItem('USER_PROJECT');
export const logout = () => {
  localStorage.removeItem('TOKEN');
  localStorage.removeItem('USER_FIRST_NAME')
  localStorage.removeItem('USER_LAST_NAME')
  localStorage.removeItem('USER_EMAIL')
  localStorage.removeItem('USER_ROLE')
  localStorage.removeItem('USER_PROJECT')
};
