/* eslint-disable camelcase */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import FormComponentEquipment from '../../../components/FormComponentEquipment';
import Sidebar from '../../../components/Sidebar/Sidebar';
import api from '../../../services/api';

class EditProjects extends Component {
    state = {
      _id: '',
      id: '',
      name: '',
      tipo: '',
      status: '',
      last_inspection: '',
      next_inspection: '',
      relatorio: '',
      ccpsv: '',
      ccmanometro: '',
      ccpressostato: '',
      error: '',
    };

    componentDidMount() {
      this.getEquipment();
    }

    handleSubmit = async (e) => {
      e.preventDefault();
      const {
        _id, id, name, status, tipo, last_inspection, next_inspection, relatorio, ccpsv, ccmanometro, ccpressostato,
      } = this.state;
      if (!id || !name || !status || !tipo || !last_inspection || !next_inspection || id === '' || name === '' || status === '' || tipo === '' || last_inspection === '' || next_inspection === '') {
        this.setState({ error: 'Preencha todos os campos para continuar!' });
      } else {
        try {
          await api.put(`/equipments/${_id}`, {
            id, name, tipo, status, last_inspection, next_inspection, relatorio, ccpsv, ccmanometro, ccpressostato,
          });
          this.goToTheBack();
        } catch (err) {
          this.setState({ error: 'Houve um problema para atualizar, verifique os dados preenchidos!' });
        }
      }
    };

    async getEquipment() {
      try {
        const { id } = this.props.match.params;
        const response = await api.get(`/equipments/${id}`);
        this.setState({
          _id: id,
          id: response.data.equipments.id,
          name: response.data.equipments.name,
          status: response.data.equipments.status,
          tipo: response.data.equipments.tipo,
          last_inspection: response.data.equipments.last_inspection,
          next_inspection: response.data.equipments.next_inspection,
          relatorio: response.data.equipments.relatorio,
          ccpsv: response.data.equipments.ccpsv,
          ccmanometro: response.data.equipments.ccmanometro,
          ccpressostato: response.data.equipments.ccpressostato,
        });
      } catch (err) {
        this.setState({ error: 'Houve um problema com a busca do projetos!' });
      }
    }

    goToTheBack() {
      try {
        this.props.history.goBack();
      } catch (err) {
        this.setState({ error: 'Houve um erro para retornar a página anterior' });
      }
    }

    render() {
      const {
        id, name, status, tipo, last_inspection, next_inspection, relatorio, ccpsv, ccmanometro, ccpressostato, error,
      } = this.state;
      return (
        <>
          <Sidebar />
          <FormComponentEquipment
            onSubmit={() => this.handleSubmit}
            onChangeId={(e) => { this.setState({ id: e.target.value }); }}
            onChangeName={(e) => { this.setState({ name: e.target.value }); }}
            onChangeTipo={(e) => { this.setState({ status: e.target.value }); }}
            onChangeStatus={(e) => { this.setState({ tipo: e.target.value }); }}
            onChangeLastInspection={(e) => { this.setState({ last_inspection: e.target.value }); }}
            onChangeNextInspection={(e) => { this.setState({ next_inspection: e.target.value }); }}
            onChangeRelatorio={(e) => { this.setState({ relatorio: e.target.value }); }}
            onChangeCCPSV={(e) => { this.setState({ ccpsv: e.target.value }); }}
            onChangeCCManometro={(e) => { this.setState({ ccmanometro: e.target.value }); }}
            onChangeCCPressostato={(e) => { this.setState({ ccpressostato: e.target.value }); }}
            onCancel={() => { this.goToTheBack(); }}
            id={id}
            name={name}
            status={status}
            tipo={tipo}
            lastInspection={last_inspection}
            nextInspection={next_inspection}
            relatorio={relatorio}
            ccpsv={ccpsv}
            ccmanometro={ccmanometro}
            ccpressostato={ccpressostato}
            error={error}
            action="Atualizar equipamento"
            titulo="Editar este Equipamento:"
          />
        </>
      );
    }
}

export default withRouter(EditProjects);
