import React from 'react';
import { Container, Form } from './styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';

const FormComponentUser = (props) => {

  const {
    onChangeNome,
    onChangeSobrenome,
    onChangeEmail,
    onChangePassword,
    onChangeRoleAssigned,
    onChangeProjects,
    error,
    onSubmit,
    onCancel,
    nome = '',
    sobrenome = '',
    email = '',
    password = '',
    roleAssigned,
    projectAssigned,
    role,
    projects,
    action,
    titulo,
  } = props;

  return (
    <Container>
      <Form onSubmit={onSubmit()}>
        { error && (
        <p>
          {error}
          {' '}
        </p>
        )}
        <h3>{titulo}</h3>
        <input
          defaultValue={nome}
          placeholder="Nome do usuário *"
          onChange={(e) => onChangeNome(e)}
          type="text"
        />
        <input
          defaultValue={sobrenome}
          placeholder="Sobrenome do usuário *"
          onChange={(e) => onChangeSobrenome(e)}
          type="text"
        />
        <input
          defaultValue={email}
          placeholder="E-mail de acesso *"
          onChange={(e) => onChangeEmail(e)}
          type="text"
        />
        <input
          defaultValue={password}
          placeholder="Senha de acesso *"
          onChange={(e) => onChangePassword(e)}
          type="password"
        />
        <FormControl className='roleSelector'>
          <InputLabel required id="select-role">Função</InputLabel>
          <Select
            labelId="select-role"
            id="select-role"
            value={roleAssigned}
            onChange={(e) => onChangeRoleAssigned(e)}
            autoWidth
          >
            <MenuItem value={'adm'}>Administrador</MenuItem>
            <MenuItem value={'moderator'}>Moderador</MenuItem>
            <MenuItem value={'visualizer'}>Visualizador</MenuItem>
            <FormHelperText>A função a ser atribuida ao usuário</FormHelperText>
          </Select>
        </FormControl>
        {role === 'adm' ? 
        (<p>Para administradores, não é necessário a seleção de projeto</p>) :  (<FormControl className='projectSelector'>
          <InputLabel id="select-project">Projeto</InputLabel>
          <Select
            labelId="select-project"
            id="select-project"
            value={projectAssigned}
            onChange={(e) => onChangeProjects(e)}
            autoWidth
          >
            {projects.map((project) => (
              <MenuItem value={project._id}>{project.title}</MenuItem>
            ))}
          </Select>
        </FormControl>)}
        <button type="submit" className="button">{action}</button>
        <button type="button" onClick={() => { onCancel(); }}>Cancelar</button>
      </Form>
    </Container>
  );
};

export default FormComponentUser;
