import styled from 'styled-components';

export const ContainerQR = styled.div`
  display: flex;
  align: center;
  margin-top: 4%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 4%;
  width: 100px;
`;

export const ListData = styled.div`
  margin-left: auto;
  margin-right: auto;
  .data_text {
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Title = styled.div` 
  .text {
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    text-align: center;
  }

`;
