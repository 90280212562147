import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import QRCode from 'qrcode.react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import * as GrIcons from 'react-icons/gr';
import api from '../../../services/api';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { ContainerQR, ListData, Title } from './style';

const ListItemLink = (props) => <ListItem button component="a" {...props} />;

class ViewEquipment extends Component {
  state = {
    error: '',
    id: '',
    name: '',
    tipo: '',
    status: '',
    lastInspection: '',
    nextInspection: '',
    relatorio: '',
    ccpsv: '',
    ccmanometro: '',
    ccpressostato: '',
    pathname: '',
  }

  componentDidMount() {
    this.getEquipment();
  }

  async getEquipment() {
    try {
      const { id } = this.props.match.params;
      const response = await api.get(`/equipments/${id}`);
      this.setState({
        id: response.data.equipments.id,
        name: response.data.equipments.name,
        tipo: response.data.equipments.tipo,
        status: response.data.equipments.status,
        lastInspection: response.data.equipments.last_inspection,
        nextInspection: response.data.equipments.next_inspection,
        relatorio: response.data.equipments.relatorio,
        ccpsv: response.data.equipments.ccpsv,
        ccmanometro: response.data.equipments.ccmanometro,
        ccpressostato: response.data.equipments.ccpressostato,
        pathname: this.props.location.pathname,
      });
    } catch (err) {
      this.setState({ error: 'Houve um erro ao captar os dados do equipamento' });
    }
  }

  render() {
    const {
      pathname, name, tipo, status, lastInspection, nextInspection, relatorio, ccpsv, ccmanometro, ccpressostato,
    } = this.state;

    return (
      <div className="root_list">
        <Sidebar />
        <ContainerQR>
          <QRCode size={150} value={`https://app.gestmanc.com${pathname}`} />
        </ContainerQR>
        <Title>
          <h3 className="text">Informações do Equipamento</h3>
        </Title>
        <ListData>
          <List component="nav" aria-label="main mailbox folders">
            <ListItem>
              <ListItemIcon>
                <GrIcons.GrCircleInformation />
              </ListItemIcon>
              <ListItemText primary="Nome" />
              <ListItemText primary={name} align="center" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <GrIcons.GrTroubleshoot />
              </ListItemIcon>
              <ListItemText primary="Tipo" />
              <ListItemText primary={tipo} align="center" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <GrIcons.GrTools />
              </ListItemIcon>
              <ListItemText primary="Status Atual" />
              <ListItemText primary={status} align="center" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <GrIcons.GrSchedulePlay />
              </ListItemIcon>
              <ListItemText primary="Última inspeção" />
              <ListItemText primary={`${lastInspection.split('T')[0].split('-')[2]}/${lastInspection.split('T')[0].split('-')[1]}/${lastInspection.split('T')[0].split('-')[0]}`} align="center" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <GrIcons.GrScheduleNew />
              </ListItemIcon>
              <ListItemText primary="Próxima inspeção" />
              <ListItemText primary={`${nextInspection.split('T')[0].split('-')[2]}/${nextInspection.split('T')[0].split('-')[1]}/${nextInspection.split('T')[0].split('-')[0]}`} align="center" />
            </ListItem>
          </List>
          <Divider />
          <List component="nav" aria-label="secondary mailbox folders">
            <ListItemLink href={relatorio} target="_blank">
              <ListItemIcon>
                <GrIcons.GrDocumentPdf />
              </ListItemIcon>
              <ListItemText primary="Relatório de Inspeção" align="center" />
            </ListItemLink>
            <ListItemLink href={ccpsv} target="_blank">
              <ListItemIcon>
                <GrIcons.GrDocumentPdf />
              </ListItemIcon>
              <ListItemText primary="Certificado Calibração PSV" align="center" />
            </ListItemLink>
            <ListItemLink href={ccmanometro} target="_blank">
              <ListItemIcon>
                <GrIcons.GrDocumentPdf />
              </ListItemIcon>
              <ListItemText primary="Certificado Calibração Manometro" align="center" />
            </ListItemLink>
            <ListItemLink href={ccpressostato} target="_blank">
              <ListItemIcon>
                <GrIcons.GrDocumentPdf />
              </ListItemIcon>
              <ListItemText primary="Certificado Calibração Pressostato" align="center" />
            </ListItemLink>
          </List>
        </ListData>

      </div>
    );
  }
}

export default withRouter(ViewEquipment);
