import React from 'react';
import { Container, Form } from './styles';

const FormComponentEquipment = (props) => {
  const {
    onChangeId,
    onChangeName,
    onChangeTipo,
    onChangeStatus,
    onChangeLastInspection,
    onChangeNextInspection,
    onChangeRelatorio,
    onChangeCCPSV,
    onChangeCCManometro,
    onChangeCCPressostato,
    error,
    onSubmit,
    onCancel,
    id = '',
    name = '',
    tipo = '',
    status = '',
    lastInspection = '',
    nextInspection = '',
    relatorio = '',
    ccpsv = '',
    ccmanometro = '',
    ccpressostato = '',
    action,
    titulo,
  } = props;

  return (
    <Container>
      <Form onSubmit={onSubmit()}>
        { error && (
        <p>
          {error}
          {' '}
        </p>
        )}
        <h3>{titulo}</h3>
        <input
          defaultValue={id}
          placeholder="ID do Equipamento"
          onChange={(e) => onChangeId(e)}
          type="text"
        />
        <input
          defaultValue={name}
          placeholder="Nome do equipamento"
          onChange={(e) => onChangeName(e)}
          type="text"
        />
        <input
          defaultValue={tipo}
          placeholder="Tipo do Equipamento"
          onChange={(e) => onChangeTipo(e)}
          type="text"
        />
        <input
          defaultValue={status}
          placeholder="Status do equipamento"
          onChange={(e) => onChangeStatus(e)}
          type="text"
        />
        <label className="label-last-inspection">Última inspeção realizada</label>
        <input
          className="input-last-inspection"
          defaultValue={lastInspection}
          label="Última inspeção realizada"
          onChange={(e) => onChangeLastInspection(e)}
          placeholder="dd-MM-yyyy"
          locate="pt-br"
          format="dd-MM-yyyy"
          type="date"
        />
        <label className="label-next-inspection">Próxima inspeção</label>
        <input
          className="input-next-inspection"
          defaultValue={nextInspection}
          label="Próxima inspeção"
          onChange={(e) => onChangeNextInspection(e)}
          placeholder="dd-MM-yyyy"
          locate="pt-br"
          format="dd-MM-yyyy"
          type="date"
        />
        <input
          defaultValue={relatorio}
          placeholder="Relatório de inspeção"
          onChange={(e) => onChangeRelatorio(e)}
          type="text"
        />
        <input
          defaultValue={ccpsv}
          placeholder="Certificado de Calibração de PSV"
          onChange={(e) => onChangeCCPSV(e)}
          type="text"
        />
        <input
          defaultValue={ccmanometro}
          placeholder="Certificado de Calibração Manometro"
          onChange={(e) => onChangeCCManometro(e)}
          type="text"
        />
        <input
          defaultValue={ccpressostato}
          placeholder="Certificado de Calibração Pressostato"
          onChange={(e) => onChangeCCPressostato(e)}
          type="text"
        />
        <button type="submit" className="button">{action}</button>
        <button type="button" onClick={() => { onCancel(); }}>Cancelar</button>
      </Form>
    </Container>
  );
};

export default FormComponentEquipment;
