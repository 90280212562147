import React from 'react';
import {
  BrowserRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import { isAuthenticated, userRole } from './services/auth';
import Home from './pages/Home';
import SignIn from './pages/Signin';
import ListProjects from './pages/Projects/ListProjects';
import CreateProjects from './pages/Projects/CreateProjects';
import EditProjects from './pages/Projects/EditProjects';
import RemoveProjects from './pages/Projects/RemoveProjects';
import ListEquipments from './pages/Equipments/ListEquipments';
import CreateEquipments from './pages/Equipments/CreateEquipments';
import EditEquipments from './pages/Equipments/EditEquipments';
import RemoveEquipments from './pages/Equipments/RemoveEquipments';
import ViewEquipment from './pages/Equipments/ViewEquipment';
import Support from './pages/Support';
import ListUsers from './pages/Users/ListUsers';
import RegisterUser from './pages/Users/RegisterUser';
import RemoveUser from './pages/Users/RemoveUser';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (userRole() === 'adm' || userRole() === 'moderator' ? (
      <Component {...props} />
    ) : (
      <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    ))}
  />
);

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (userRole() === 'adm' ? (
      <Component {...props} />
    ) : (
      <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    ))}
  />
);

const VisualizerRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (userRole() === 'adm' || userRole() === 'moderator' || userRole() === 'visualizer' ? (
      <Component {...props} />
    ) : (
      <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    ))}
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <AdminRoute exact path="/" component={Home} />
      <Route exact path="/login" component={SignIn} />
      <VisualizerRoute exact path="/support" component={Support} />
      {/* Projetos */}
      <AdminRoute exact path="/projetos" component={ListProjects} />
      <AdminRoute exact path="/projetos/create" component={CreateProjects} />
      <AdminRoute exact path="/projetos/edit/:id" component={EditProjects} />
      <AdminRoute exact path="/projetos/remove/:id" component={RemoveProjects} />
      {/* Equipamentos */}
      <PrivateRoute exact path="/projetos/:id/equipments" component={ListEquipments} />
      <PrivateRoute exact path="/projetos/:id/equipments/create" component={CreateEquipments} />
      <VisualizerRoute exact path="/projetos/:id/equipments/view/:id" component={ViewEquipment} />
      <PrivateRoute exact path="/projetos/:id/equipments/edit/:id" component={EditEquipments} />
      <PrivateRoute exact path="/projetos/:id/equipments/remove/:id" component={RemoveEquipments} />
      {/* Usuários */}
      <AdminRoute exact path="/users" component={ListUsers} />
      <AdminRoute exact path="/users/create" component={RegisterUser} />
      <AdminRoute exact path="/user/remove/:id" component={RemoveUser} />
      <Route path="*" component={() => <h1>Page not found</h1>} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
