import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  animation-name: up;
  animation-duration: 400ms;
  animation-timing-function: ease-in-out;

  @keyframes up {
    from {
        opacity: 0;
        transform: translateY(25px)
    }
    
    to {
        opacity: 1;
    }
  }
`;
export const Form = styled.form`
  width: 400px;
  background: #fff;
  img {
    width: 450px;
    margin: 10px 0 40px;
  }
  .container-logo{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
  }
  .container-name{
    padding-left: 7px;
    margin-bottom: 10px;
    color: #535353;
  }
  .container-forms{
    border: 1px solid #979797;
    border-radius: 13px;
    background: #fff;
    padding: 20px;
  }
  .box-copyright{
    background: #fff;
  }
  .box-error {
    color: #ff3333;
    margin-bottom: 15px;
    border: 1px solid #ff3333;
    padding: 10px;
    width: 100%;
    text-align: center;
    animation-name: up;
    animation-duration: 300ms;
    animation-timing-function: ease-in-out;
    @keyframes up {
      from {
          opacity: 0;
          transform: translateY(25px)
      }
      
      to {
          opacity: 1;
      }
    }
  }
  
  button {
    color: #fff;
    font-weight: 900;
    font-size: 12px;
    margin-top: 10px;
    background: #002FCF;
    height: 30px;
    border: 0;
    border-radius: 6px;
    width: 37%;
    transition: 200ms;
  }
  button:hover {
    background: #0000BB;
  }
  hr {
    margin: 20px 0;
    border: none;
    border-bottom: 1px solid #002FCF;
    width: 100%;
  }
  a {
    font-size: 16;
    font-weight: bold;
    color: #999;
    text-decoration: none;
  }
`;
