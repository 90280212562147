import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import FormComponentProject from '../../../components/FormComponentProject';
import Sidebar from '../../../components/Sidebar/Sidebar';
import api from '../../../services/api';

class Create extends Component {
    state = {
      title: '',
      description: '',
      equipments: [],
      error: '',
    };

    handleSubmit = async (e) => {
      e.preventDefault();
      const { title, description, equipments } = this.state;
      if (!title || !description) {
        this.setState({ error: 'Preencha todos os campos para continuar!' });
      } else {
        try {
          await api.post('/projects', { title, description, equipments });
          this.props.history.push('/projetos');
        } catch (err) {
          this.setState({ error: 'Houve um problema com o cadastro, verifique os dados preenchidos!' });
        }
      }
    };

    render() {
      const { error } = this.state;

      return (
        <>
          <Sidebar />
          <FormComponentProject
            onSubmit={() => this.handleSubmit}
            onChangeTitle={(e) => { this.setState({ title: e.target.value }); }}
            onChangeDescription={(e) => { this.setState({ description: e.target.value }); }}
            to="/projetos"
            error={error}
            action="Cadastrar projeto"
            titulo="Cadastrar novo projeto:"
          />
        </>
      );
    }
}

export default withRouter(Create);
