import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Box } from './styles';
import Sidebar from '../../../components/Sidebar/Sidebar';
import api from '../../../services/api';

class RemoveUser extends Component {
    state = {
      user: '',
      error: '',
    };

    componentDidMount() {
      this.getEquipment();
    }

    async handleDelete() {
      try {
        const { id } = this.props.match.params;
        await api.delete(`/auth/user/${id}`);
        this.props.history.goBack();
      } catch (error) {
        this.setState({ error: 'Houve um erro enquanto tentavamos deletar este usuario!' });
      }
    }

    async getEquipment() {
      try {
        const { id } = this.props.match.params;
        const response = await api.get(`/auth/user/${id}`);
        this.setState({
          user: response.data.user.nome,
        });
      } catch (error) {
        this.setState({ error: 'Houve um problema com a busca do equipamento!' });
      }
    }

    goToTheBack() {
      try {
        this.props.history.goBack();
      } catch (err) {
        this.setState({ error: 'Houve um erro para retornar a página anterior' });
      }
    }

    render() {
      const { user } = this.state;
      return (
        <>
          <Sidebar />
          <Container>
            <Box>
              <h2>
                Você deseja mesmo remover o usuário
                {' '}
                {user}
              </h2>
              <button type="button" onClick={() => { this.handleDelete(); }}>Sim</button>
              <button type="button" onClick={() => { this.goToTheBack(); }}>Cancelar</button>
            </Box>
          </Container>
        </>
      );
    }
}

export default withRouter(RemoveUser);
