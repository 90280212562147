import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import FormComponentUser from '../../../components/FormComponentUser';
import Sidebar from '../../../components/Sidebar/Sidebar';
import api from '../../../services/api';


class RegisterUser extends Component {
    state = {
      nome: '',
      sobrenome: '',
      email: '',
      password: '',
      roleAssigned: '',
      error: '',
      projects: [],
      project: '',
    };

    componentDidMount() {
      this.getProjects();
    }

    handleSubmit = async (e) => {
      e.preventDefault();
      const {
        nome, sobrenome, email, password, roleAssigned, project
      } = this.state;

      if (roleAssigned === 'adm'){
        if (!nome || !sobrenome || !email || !password || !roleAssigned || nome === '' || sobrenome === '' || email === '' || password === '' || roleAssigned === '') {
          this.setState({ error: 'Preencha todos os campos para continuar!' });
        } else {
          try {
            await api.post('/auth/register', {
              nome, sobrenome, email, roleAssigned, password,
            });
            this.props.history.push('/users');
          } catch (err) {
            this.setState({ error: 'Houve um problema com o cadastro, verifique os dados preenchidos!' });
          }          
        }
      } else {
        if (!nome || !sobrenome || !email || !password || !roleAssigned || !project || nome === '' || sobrenome === '' || email === '' || password === '' || roleAssigned === '' || project === '') {
          this.setState({ error: 'Preencha todos os campos para continuar!' });
        } else {
          try {
            await api.post('/auth/register', {
              nome, sobrenome, email, roleAssigned, project, password,
            });
            this.props.history.push('/users');
          } catch (err) {
            this.setState({ error: 'Houve um problema com o cadastro, verifique os dados preenchidos!' });
          }
        }
      };
      }

    goToTheBack() {
      try {
        this.props.history.goBack();
      } catch (err) {
        this.setState({ error: 'Houve um erro para retornar a página anterior' });
      }
    }

    async getProjects() {
      try {
        const response = await api.get('/projects');
        const { projects } = response.data;
        this.setState({ projects });
      } catch (err) {
        this.setState({ error: 'Houve um erro para retornar a página anterior' })
      }
    }

    render() {
      const { error, roleAssigned ,projects } = this.state;

      const handleChange = (event) => {    
        this.setState({ roleAssigned: event.target.value});
      };

      const handleChangeProjects = (event) => {    
        this.setState({ project: event.target.value});
      };

      return (
        <>
          <Sidebar />
          <FormComponentUser
            onSubmit={() => this.handleSubmit}
            onChangeNome={(e) => { this.setState({ nome: e.target.value }); }}
            onChangeSobrenome={(e) => { this.setState({ sobrenome: e.target.value }); }}
            onChangeEmail={(e) => { this.setState({ email: e.target.value }); }}
            onChangePassword={(e) => { this.setState({ password: e.target.value }); }}
            onChangeRoleAssigned={handleChange}
            onChangeProjects={handleChangeProjects}
            onCancel={() => { this.goToTheBack(); }}
            role={roleAssigned}
            error={error}
            projects={projects}
            action="Cadastrar usuario"
            titulo="Cadastrar um novo usuario:"
          />
        </>
      );
    }
}

export default withRouter(RegisterUser);
