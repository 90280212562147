import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Form } from './styles';

const FormComponentProject = (props) => {
  const {
    onChangeTitle,
    onChangeDescription,
    to,
    error,
    onSubmit,
    title = '',
    description = '',
    action,
    titulo,
  } = props;

  return (
    <Container>
      <Form onSubmit={onSubmit()}>
        { error && (
        <p>
          {error}
          {' '}
        </p>
        )}
        <h3>{titulo}</h3>
        <input
          defaultValue={title}
          placeholder="Titulo"
          onChange={(e) => onChangeTitle(e)}
          type="text"
        />
        <input
          defaultValue={description}
          placeholder="Descrição"
          onChange={(e) => onChangeDescription(e)}
          type="text"
        />
        <button type="submit" className="button">{action}</button>
        <Link to={to} className="link"><button type="button">Cancelar</button></Link>
      </Form>
    </Container>
  );
};

export default FormComponentProject;
