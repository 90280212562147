import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  animation-name: up;
  animation-duration: 400ms;
  animation-timing-function: ease-in-out;

  @keyframes up {
    from {
        opacity: 0;
        transform: translateY(25px)
    }
    
    to {
        opacity: 1;
    }
  }
`;
