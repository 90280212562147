import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from './styles';
import logo from '../../assets/logo/logo.png';

class Support extends Component {
  render() {
    return (
      <Container>
        <div>
          <img src={logo} alt="InvEng Logo" />
          <a href="mailto:natanascimentom@outlook" style={{ color: 'black', textDecoration: 'none' }}><h1>Entre em contato com o time de desenvolvimento</h1></a>
        </div>
      </Container>
    );
  }
}
export default withRouter(Support);
