/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { FiTrash, FiEdit3, FiList } from 'react-icons/fi';
import { Container, Table } from './styles';
import Sidebar from '../../../components/Sidebar/Sidebar';
import api from '../../../services/api';

class ListProjects extends Component {
  state = {
    projects: [],
    error: '',
  }

  componentDidMount() {
    this.getProjects();
  }

  async getProjects() {
    try {
      const response = await api.get('/projects');
      const { projects } = response.data;

      this.setState({ projects });
    } catch (err) {
      this.setState({ error: 'Houve um problema ao carregar os projetos' });
    }
  }

  render() {
    const { projects } = this.state;

    return (
      <>
        <Sidebar />
        <Container>
          <Table striped>
            <thead>
              <tr>
                <th>Titulo</th>
                <th>Descrição</th>
                <th>Equipamentos</th>
                <th>Editar</th>
                <th>Remover</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project) => (
                <tr key={project._id}>
                  <td>{project.title}</td>
                  <td>{project.description}</td>
                  <td>
                    <Link to={`/projetos/${project._id}/equipments`}>
                      <button type="button">
                        <FiList />
                      </button>
                    </Link>
                  </td>
                  <td>
                    <Link to={`/projetos/edit/${project._id}`}>
                      <button type="button">
                        <FiEdit3 />
                      </button>
                    </Link>
                  </td>
                  <td>
                    <Link to={`/projetos/remove/${project._id}`}>
                      <button type="button" className="remove">
                        <FiTrash />
                      </button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      </>
    );
  }
}

export default withRouter(ListProjects);
